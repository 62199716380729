<template>
  <div
    class="ui-collapse"
    :class="[
      { animate: animate !== false, 'collapse-hidden': !visible, collapsing: isCollapsing },
      `-direction-${direction}`,
    ]"
    :style="style"
  >
    <div ref="collapse" :class="innerClass" :style="innerStyle">
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  visible: Boolean,
  direction: {
    type: String,
    default: 'v',
  },
  animate: {
    type: Boolean,
    default: true,
  },
  innerClass: String,
  innerStyle: String,
})

const collapse = ref(null)

const width = ref(0)
const height = ref(0)
const isCollapsing = ref(false)

watch(
  () => props.visible,
  (newValue) => {
    collapsing()
  },
)

const style = computed(() => {
  if (props.direction == 'v')
    return {
      'max-height': (height.value || collapse.value?.clientHeight) + 'px',
    }
  else
    return {
      'max-width': (width.value || collapse.value?.clientWidth) + 'px',
    }
})

function resize() {
  if (collapse.value) {
    height.value = collapse.value.clientHeight
    width.value = collapse.value.clientWidth

    if (props.visible) {
      collapsing()
    }
  }
}

function collapsing() {
  if (props.animate !== false) {
    isCollapsing.value = true
    setTimeout(() => {
      isCollapsing.value = false
    }, 300)
  }
}

let resizeObserver

onMounted(() => {
  resize()

  try {
    resizeObserver = new ResizeObserver(resize)
    resizeObserver.observe(collapse.value)
  } catch (e) {}
})

onBeforeUnmount(() => {
  if (resizeObserver) resizeObserver.disconnect()
})
</script>

<style lang="scss">
.ui-collapse {
  &.collapsing,
  &.collapse-hidden {
    overflow: hidden;
  }

  &.animate {
    transition: all 0.3s;
  }

  &.-direction-v {
    &.collapse-hidden {
      max-height: 0 !important;
    }
  }

  &.-direction-h {
    position: relative;
    height: 100%;

    > div {
      position: absolute;
    }

    &.collapse-hidden {
      max-width: 0 !important;
    }
  }
}
</style>
